@import 'reset.sass';
@import 'global.sass';
@import 'fonts.sass';

body {
  font-family: "Nexa", sans-serif;
  overflow-x: hidden;
  background-color: #0b0c28; }

button {
  font-family: "Nexa", sans-serif; }

input {
  font-family: "Nexa", sans-serif;

  &::placeholder {
    font-family: "Nexa", sans-serif; } }

.preloader-text {
  font-size: 18px;
  font-weight: 800; }
