@import "../../../../global";
$consentbuttonnotchsize: 10px;

.consent-wrapper {
  width: 90%;
  text-align: center;

  .consent-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px; }

  .consent-scopes {
    font-size: 14px;
    text-align: left;
    margin-bottom: 40px;

    .consent-scopes-item {
      margin-bottom: 5px;
      font-weight: 700;
      overflow-wrap: break-word; }

    & span {
      display: block; } } }

.consent-button-wrapper {
  @include flex-props-column-center;

  &button:first-child {
    margin-bottom: 20px; } }
