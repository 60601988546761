@import "../../global.sass";
$flagnotchsize: 7px;
$modalnotchsize: 10px;

.lang-modal-overlay {
  @include flex-props-center;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(13px);
  z-index: 3;

  .lang-modal-overlay-logo {
    position: absolute;
    top: 30px;
    left: 30px; }

  .lang-modal-border-wrapper {
    width: 360px;
    height: 220px;
    background-color: black;
    box-sizing: border-box;
    clip-path: polygon(0 0, #{$modalnotchsize} 0, calc(100% - #{$modalnotchsize}) 0%, 100% #{$modalnotchsize}, 100% calc(100% - #{$modalnotchsize}), calc(100%) 100%, #{$modalnotchsize} 100%, 0 100%);
    position: relative;
    @include phone {
      width: 300px; }

    .lang-modal-wrapper {
      width: 358px;
      height: 218px;
      position: absolute;
      background-color: white;
      top: 1px;
      left: 1px;
      clip-path: polygon(0 0, #{$modalnotchsize} 0, calc(100% - #{$modalnotchsize}) 0%, 100% #{$modalnotchsize}, 100% calc(100% - #{$modalnotchsize}), calc(100%) 100%, #{$modalnotchsize} 100%, 0 100%);
      @include phone {
        width: 298px; }

      .lang-modal-header {
        width: 100%;
        padding: 18px 20px;
        @include flex-props-space-between;

        .lang-modal-header-title {
          font-weight: 900;
          font-size: 12px;
          text-transform: uppercase; }

        .lang-modal-header-close {
          width: 15px;
          height: 15px;
          position: relative;
          cursor: pointer;

          .lang-modal-header-close-line {
            width: 100%;
            height: 1.5px;
            border-radius: 2px;
            transform: rotate(45deg);
            background-color: black;
            position: absolute;
            top: 4px;

            &:last-child {
              transform: rotate(-45deg); } } } }

      .lang-modal-header-hr {
        width: 100%;
        border: 1px solid black;
        margin: 0 0 17px 0; }

      .lang-modal-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 20px;

        .lang-modal-item {
          margin-bottom: 15px;
          cursor: pointer;
          width: 33%;
          display: flex;
          align-items: center;
          @include phone {
            width: 50%; }

          .lang-modal-flag {
            margin-right: 6px;
            clip-path: polygon(0 0, #{$flagnotchsize} 0, calc(100% - #{$flagnotchsize}) 0%, 100% #{$flagnotchsize}, 100% calc(100% - #{$flagnotchsize}), calc(100%) 100%, #{$flagnotchsize} 100%, 0 calc(100% - #{$flagnotchsize})); }

          .lang-modal-name {
            font-weight: 700;
            font-size: 11px;
            margin-top: 5px;
            text-transform: uppercase; } }


        &:last-child {
          margin-bottom: 0; } } } } }

.lang-modal-overlay-active {
  display: flex; }
