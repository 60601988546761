@import "../../../../global.sass";

.box-footer {
  width: 100%;
  @include flex-props-space-between;
  padding-left: $boxnotchsize;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;

  .box-footer-lang-switcher {
    display: inline-block;
    width: auto;
    border: none;
    padding: 0;
    background-color: transparent;
    color: white;
    font-weight: 700;
    font-size: 12px;
    cursor: pointer; }

  .box-footer-links {
    font-weight: 700;

    .box-footer-link {
      color: white; }

    .box-footer-link:first-child {
      margin-right: 30px; } } }


