@import '../../global.sass';

.container-404 {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/images/404-background.jpg");
  position: relative;
  @include flex-props-column-center;
  @include background-props;

  .logo-white-404 {
    position: absolute;
    top: 30px;
    left: 30px; }

  .title-404 {
    margin-bottom: 50px; }

  .text-404 {
    margin-top: 40px; } }
