@import "../../../global";

.account-selector {
  @include flex-props-column-center;

  &-preloader {
    &-wrapper {
      @include flex-props-column-center; }

    &-text {
      font-size: 16px;
      margin-top: 20px;
      font-weight: 700; } }

  .box-black-hr {
    width: 122%;
    margin-left: -36px;
    margin-top: 20px;

    @include phone {
      width: 112%;
      margin-left: -15px; } }

  .account-selector-buttons {
    @include flex-props-column-center;
    width: 100%; }

  .account-selector-button {
    width: 100%;
    background: #F5F7FF;
    border: 1px solid #000000;
    @include flex-props-space-between;
    padding: 12px 15px;
    margin-bottom: 20px;
    cursor: pointer;
    color: #000; }

  .account-selector-button:last-child {
    margin-bottom: 0; }

  .account-selector-button-icon {
    margin-right: 13px; }

  .account-selector-button-wrapper {
    @include flex-props-center; } }
