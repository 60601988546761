@import "../../../global";

.account-info-session-wrapper {
  @include flex-props-center;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0; }

  .account-info-session {

    .dropdown {
      background-color: #F5F7FF;
      border: 1px solid #E2E6F8;

      &:before {
        border-right-color: #E2E6F8; }

      &:after {
        display: none; }

      .dropdown-arrow-wrapper {
        border-left: none; } }

    .dropdown-content {
      border: 1px solid #E2E6F8;
      background: rgba(245, 247, 255, 0.4);

      &:before {
        border-right-color: #E2E6F8; } }

    .account-info-session-data {
      @include flex-props-column-center;
      align-items: flex-start;
      padding-left: 20px;
      margin-bottom: 20px;

      .account-info-session-data-subtitle {
        color: #0037C1;
        font-weight: 700;
        font-size: 12px;
        margin-bottom: 5px;
        text-align: left; }

      .account-info-session-data-title {
        font-weight: 700;
        font-size: 14px;
        text-align: left;

        &:visited {
          color: #000; } } }

    .account-info-session-data-last {
      margin-bottom: 40px; }

    .account-info-session-terminate {
      width: 100%;
      padding: 10px 0;
      background-color: #000;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      color: #fff;
      border: none;
      font-weight: 800;
      font-size: 14px;
      cursor: pointer; }


    .account-info-session-header {
      height: 100%;
      @include flex-props-center;
      justify-content: flex-start;

      .account-info-session-header-image-wrapper {
        height: 48px;
        width: 48px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #F5F7FF;
        z-index: 2;
        @include flex-props-center;

        .account-info-session-header-image {
          height: 60%; }

        .account-info-session-header-image-preloader {
          height: 60%;
          width: 60%; } }

      .account-info-session-header-text {
        margin-left: 40px;
        font-weight: 700;
        font-size: 14px; } } } }
