@import "../../../auth/box/boxHeader/index";
@import "../../../../global.sass";

.box-nav {
  width: 100%;
  @include flex-props-space-between;

  .box-nav-item {
    color: #fff;
    @include flex-props-center;

    .box-nav-item-text {
      font-weight: 300;
      font-size: 12px; }

    .box-nav-item-text-clickable {
      cursor: pointer; }

    .box-nav-item-network-icon {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: #47AD51;
      border: 1px solid #7ADABD;
      margin-right: 7px; }

    .box-nav-item-arrow {
      margin-left: 7px;
      width: 8px; } } }

