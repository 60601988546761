.ticker-wrap {
    overflow-x: hidden;
    position: absolute;
    top: 30%;
    .ticker {
        width: 100vw;
        .ticker-item {
            width: 100%;
            background-image: url("../../../assets/images/account-ticker.svg");
            height: 34px;
            background-repeat: y-repeat; } } }


// Animation for ticker loop (if needed in the future)
// @keyframes ticker
//     0%
//         transform: translate3d(-40%, 0, 0)

//     100%
//         transform: translate3d(-20%, 0, 0)
