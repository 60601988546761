@import "../../global";

.modal-overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(11, 12, 39, 0.8);
  z-index: 3;
  @include flex-props-center;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .modal-content {
    width: 340px;
    border: 1px solid #000;
    height: auto;
    background-color: white;
    @include flex-props-column-center;
    @include phone {
      width: 300px; }

    .modal-header {
      width: 100%;
      text-align: center;
      padding: 18px 0;
      border-bottom: 1px solid #000;
      font-weight: 700;
      font-size: 12px;
      color: black;
      text-transform: uppercase; }

    .modal-header-warning {
      background-color: #EBAB28;
      color: white; }

    .modal-header-info {
      background-color: #0037C1;
      color: white; }

    .modal-text {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 15px;
      padding: 0 20px;
      line-height: 24px;
      white-space: pre-line; }

    .modal-content-wrapper {
      @include flex-props-column-center;
      padding: 15px 0; } } }

.modal-overlay-opened {
  display: flex; }
