@import "../../../global";

.preloader-wrapper {
  @include flex-props-column-center;

  .preloader-text {
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
    font-weight: 700; } }
