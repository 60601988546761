@import '../../../global.sass';

.history-item {
    cursor: pointer;
    background-color: #F5F7FF;
    display: flex;
    align-items: center;
    height: 40px;
    width 100% {}
    margin-bottom: 20px;
    border: 1px solid #E2E6F8;

    .history-item-left {
        background-color: #000;
        align-self: baseline;
        color: #ffffff;
        height: 40px;
        width: 40px;
        line-height: 40px;

        svg {
            line-height: 40px;
            display: block;
            margin: 0 auto;
            height: 40px;
            width: 20px; } }

    .history-item-amount {
        font-size: 14px;
        font-weight: 700;
        flex-grow: 2;
        text-align: right;
        margin-right: 10px; }


    .history-item-right {
        font-size: 14px;
        font-weight: 700;
        flex-grow: 2;
        text-align: left;
        margin-left: 10px;

        .history-item-name {
            font-weight: 700;
            font-size: 14px;
            line-height: 14px;
            margin-bottom: 4px; }

        .history-item-hash {
            font-weight: 700;
            font-size: 10px;
            color: #0037C1; } } }

