@import "../../../global";

.accounts-wrapper {
  text-align: center;
  @include flex-props-column-center;
  margin-bottom: 40px;

  .accounts-title {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 18px; } }

.accounts-preloader {
  text-align: center;
  @include flex-props-column-center;
  margin-bottom: 40px;

  .accounts-preloader-text {
    font-weight: 700; } }
