@import "../../global";
$dropdownpolygon: polygon(0 0, #{$dropdownnotchsize} 0, calc(100% - #{$dropdownnotchsize}) 0%, 100% #{$dropdownnotchsize}, 100% calc(100% - #{$dropdownnotchsize}), calc(100%) 100%, #{$dropdownnotchsize} 100%, 0 calc(100% - #{$dropdownnotchsize}));

.dropdown-wrapper {
  @include flex-props-column-center;
  width: 333px;
  margin-bottom: 20px;
  @include phone {
    width: 270px; }

  &:last-child {
    margin-bottom: 0; }

  .dropdown {
    background-color: white;
    width: 100%;
    height: 50px;
    border: 1px solid #000;
    position: relative;
    @include flex-props-space-between;

    &:after, &:before {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background-color: white;
      border-right: 1px solid black; }

    &:after {
      left: -8px;
      bottom: -8px;
      transform: rotate(-45deg); }

    &:before {
      right: -8px;
      top: -8px;
      transform: rotate(135deg); }

    .dropdown-title {
      width: 85%;
      height: 100%;
      font-size: 14px;
      line-height: 18px;
      padding-left: 20px;
      text-align: left;
      @include phone {
        padding-left: 10px;
        font-size: 12px; } }

    .dropdown-arrow-wrapper {
      width: 40px;
      border-left: 1px solid #000;
      height: 100%;
      cursor: pointer;
      @include flex-props-center;

      .dropdown-arrow {
        transition: transform .5s ease; }

      .dropdown-arrow-active {
        transform: rotate(180deg); } } }

  .dropdown-active:after {
    display: none; }

  .dropdown-content {
    padding: 20px;
    width: 333px;
    border: 1px solid #000;
    border-top: none;
    position: relative;
    @include phone {
      width: 270px; }

    &:before {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background-color: white;
      border-right: 1px solid black;
      left: -8px;
      bottom: -8px;
      transform: rotate(-45deg);
      z-index: 2; } } }
