@import "../../../../global.sass";

.box-nav-wrapper {
  width: 100%;
  @include flex-props-space-between;
  font-weight: 750;
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 15px;
  text-transform: uppercase;
  padding-right: $boxnotchsize;

  .box-nav-text {
    color: white; }

  .box-nav-connecting {
    justify-self: flex-end; }

  .box-nav {
    display: flex;

    .box-nav-back {
      cursor: pointer; }

    .box-nav-cancel {
      display: flex;
      cursor: pointer;
      .box-nav-close {
        width: 10px;
        height: 10px;
        margin-right: 10px;
        position: relative;

        .box-nav-close-line {
          width: 100%;
          height: 2px;
          background-color: white;
          border-radius: 5px;
          position: absolute;
          top: 4px; }

        .box-nav-close-line:first-child {
          transform: rotate(-45deg); }

        .box-nav-close-line:last-child {
          transform: rotate(45deg); } } }

    .box-nav-arrow {
      margin-right: 11px; } } }
