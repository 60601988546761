$buttonnotchsize: 10px;
$buttonpolygon: polygon(0 0, #{$buttonnotchsize} 0, calc(100% - #{$buttonnotchsize}) 0%, 100% #{$buttonnotchsize}, 100% calc(100% - #{$buttonnotchsize}), calc(100%) 100%, #{$buttonnotchsize} 100%, 0 calc(100% - #{$buttonnotchsize}));

.auth-button {
  width: 150px;
  min-height: 40px;
  padding: 5px;
  box-sizing: border-box;
  clip-path: $buttonpolygon;
  border: none;
  cursor: pointer;
  margin-bottom: 12px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 12px;
  white-space: normal;

  &:last-child {
    margin-bottom: 0; } }

.auth-button-wrapper {
  width: 150px;
  min-height: 40px;
  box-sizing: border-box;
  position: relative;
  clip-path: $buttonpolygon;
  margin: 0 auto 12px;

  &:last-child {
    margin-bottom: 0; }

  .auth-button {
    min-height: 38px;
    height: calc(100% - 2px);
    width: calc(100% - 2px); } }

.auth-black-button {
  background-color: #000;
  color: #fff; }

.auth-white-button-wrapper {
  background-color: #000;

  .auth-white-button {
    position: absolute;
    background-color: #fff;
    color: #000;
    top: 1px;
    left: 1px;
    //width: 148px
 } }    //height: 38px

.auth-green-button-wrapper {
  background-color: #0BFFB7;

  .auth-green-button {
    position: absolute;
    background-color: #000;
    color: #0BFFB7;
    top: 1px;
    left: 1px;
    //width: 148px
 } }    //height: 38px
