@import "../../../../global";

.seed-add-account {
  width: 340px;
  @include phone {
    width: 260px; }

  .seed-add-input-label {
    position: relative;
    width: 100%;
    margin-bottom: 25px;
    display: block;
    height: auto;

    &:after {
      content: "";
      position: absolute;
      background-color: white;
      right: -7px;
      top: -7px;
      width: 15px;
      height: 15px;
      transform: rotate(45deg);
      border-bottom: 1px solid #000; }

    .seed-add-input-title {
      font-size: 10px;
      font-weight: 700;
      position: absolute;
      padding: 0 2px;
      top: -3px;
      left: 15px;
      background-color: #fff;
      text-transform: uppercase; }

    .seed-add-input {
      width: 100%;
      height: 40px;
      border: 1px solid #000;
      padding-left: 20px;

      &::placeholder {
        color: #0000004D;
        font-size: 14px; }

      &:focus {
        outline: none; } } }

  .seed-add-description {
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 40px;
    line-height: 20px; }

  .seed-add-description-smargin {
    margin-bottom: 20px; } }
