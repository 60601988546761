@import "../../global";

.account-info {
  background-color: #0B0C27;
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  align-items: center;
  text-align: center;
  background-image: url("../../assets/images/auth-background.jpg");
  @include background-props;

  .box {
    display: flex;
    align-items: center;
    justify-content: center; }

  .account-info-content {
    width: 100%;

    .account-info-tabs {
      @include flex-props-space-between; }

    .account-info-tab {
      width: 33.3%;
      color: #000;
      letter-spacing: 0.5px;
      font-weight: 700;
      font-size: 21px;
      padding-bottom: 20px;
      border-bottom: 3px solid #000;
      transition: .4s ease border-bottom-color, border-left-color, border-right-color, border-top-color, color;
      cursor: pointer; }

    .account-info-tab-guest {
      width: 50%; }

    .account-info-tab-active {
      color: #0037C1;
      border-bottom: 3px solid #0037C1; }

    .account-info-tab-inactive {
      color: #858593;
      border-bottom: 3px solid #858593; }

    .account-info-tabs-content {
      margin: 0;
      padding: 0 30px; }

    .load-more-button {
      cursor: pointer; } } }



