@font-face {
  font-family: "Nexa";
  font-style: normal;
  font-weight: 300;
  src: local('Nexa'), url("./assets/fonts/Nexa/Nexa-Light.ttf") format("truetype"); }

@font-face {
  font-family: "Nexa";
  font-style: normal;
  font-weight: 350;
  src: local('Nexa'), url("./assets/fonts/Nexa/Nexa-Book.ttf") format("truetype"); }

@font-face {
  font-family: "Nexa";
  font-style: normal;
  font-weight: 400;
  src: local('Nexa'), url("./assets/fonts/Nexa/Nexa-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Nexa";
  font-style: normal;
  font-weight: 700;
  src: local('Nexa'), url("./assets/fonts/Nexa/Nexa-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "Nexa";
  font-style: normal;
  font-weight: 800;
  src: local('Nexa'), url("./assets/fonts/Nexa/Nexa-ExtraBold.ttf") format("truetype"); }

@font-face {
  font-family: "Nexa";
  font-style: normal;
  font-weight: 850;
  src: local('Nexa'), url("./assets/fonts/Nexa/Nexa-Heavy.ttf") format("truetype"); }

@font-face {
  font-family: "Nexa";
  font-style: normal;
  font-weight: 900;
  src: local('Nexa'), url("./assets/fonts/Nexa/Nexa-Black.ttf") format("truetype"); }
