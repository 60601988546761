@import "../../global";

// Notification Library customization

.Toastify__toast-icon {
  display: none !important; }

.notification-error {
  border: 2px solid #DB1571;
  border-radius: 0 !important;
  position: relative; }

.Toastify__progress-bar-error {
  background-color: #DB1571 !important; }

.notification-success {
  border: 2px solid #0BFFB7;
  border-radius: 0 !important;
  position: relative; }

.Toastify__progress-bar--success {
  background-color: #0BFFB7 !important; }

.notification-container {
  @include flex-props-space-between;

  .notification-icon {
    justify-self: flex-start;
    align-self: flex-start; }

  .notification-content {
    font-family: "Nexa", sans-serif;
    margin-left: 18px;
    flex-grow: 2;

    .notification-header {
      font-weight: 800;
      font-size: 14px;
      color: #000;
      margin-bottom: 10px; }

    .notification-text {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #000;
      overflow-wrap: break-word;
      width: 220px; } } }
