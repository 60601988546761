@import "../../../global";

.login-options-text {
  margin-bottom: 20px;
  font-size: 14px;
  display: block; }

.login-qr {
  margin-bottom: 40px; }

.error {
  @include flex-props-column-center;

  .error-image {
    width: 150px;
    height: auto;
    margin-bottom: 25px; }

  .error-title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    margin-bottom: 15px;
    max-width: 80%; }

  .error-description {
    color: #000;
    opacity: 0.6;
    font-size: 14px;
    line-height: 18px;
    max-width: 80%;
    white-space: pre-line; } }
