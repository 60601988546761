@import "../../../global";

.address-swiper {
  width: 400px;

  @include phone {
    width: 300px; }

  ul li {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    @include phone {
      padding: 0 10px !important; } }

  ul li:first-child {
    padding-right: 0 !important; }


  .address-swiper-item {
    width: 310px;
    position: relative;

    //&:before
    //  content: ""
    //  height: 100%
    //  width: 50px
    //  background: linear-gradient(0.25turn, transparent, #fff)
    //  position: absolute
    //  left: 0
    //  top: 0
    //  z-index: 2

    .account-dropdown {
      width: 308px; }

    @include phone {
      width: 250px;
      margin-left: 30px;
      overflow-y: hidden;

      //&:before
      //  display: none

      .account-dropdown {
        width: 248px; }

      .account-dropdown-border-container .account-dropdown .account-dropdown-connections {
        width: 40px;
        border-left: 1px solid #000; } } }

  .address-swiper-item-active {
    &:before {
      display: none; } } }
