@import "../../global";

.notch-block {
  background-color: #fff;
  width: 100%;
  height: auto;

  &:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    border-color: #fff;
    border-style: solid;
    border-width: 10px; }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    border-color: #fff;
    border-style: solid;
    border-width: 10px; } }

.notch-block-main {
  background-color: #fff;
  position: relative;
  min-height: 375px;
  width: 404px;
  @include flex-props-column-center;

  @include phone {
    width: 300px;
    min-height: 280px; }

  &:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    border-color: #190f39 #190f39 #fff #fff;
    border-style: solid;
    border-width: 10px; }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    border-color: #fff #fff #190f39 #190f39;
    border-style: solid;
    border-width: 10px; } }

.notch-block-border {
  &:before {
    width: 30px;
    height: 30px;
    background: #fff;
    right: -15px;
    top: -15px;
    border: none;
    border-bottom: 1px solid #000;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }

  &:after {
    width: 30px;
    height: 30px;
    background: #fff;
    left: -15px;
    bottom: -15px;
    border: none;
    border-top: 1px solid #000;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); } }

