@import '../../global.sass';

.account-info-tabs {
    h5 {
        font-size: 20px;
        margin-bottom: 20px;
        font-weight: 600; }
    h6 {
        font-size: 12px;
        margin-bottom: 40px;
        line-height: 20px; } }
