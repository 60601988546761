$boxnotchsize: 20px;
$dropdownnotchsize: 10px;

@mixin background-props {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

@mixin flex-props-center {
  display: flex;
  align-items: center;
  justify-content: center; }

@mixin flex-props-column-center {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center; }

@mixin flex-props-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center; }

@mixin phone {
  @media (max-width: 768px) {
    @content; } }

@mixin small-phone {
  @media (max-width: 500px) {
    @content; } }
