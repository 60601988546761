@import '../../../global.sass';

.notch-block-main {
  position: relative;

  .box-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(172, 36, 255, 0.25);
    filter: blur(25px);
    z-index: -1; }

  .box-header {
    width: 100%;

    .box-header-title {
      padding: 0 10px;
      margin-top: 40px;
      font-weight: 900;
      text-transform: uppercase;
      font-size: 22px;
      line-height: 26px;
      display: inline-block;
      @include phone {
        font-size: 20px;
        margin-top: 30px; } }

    .box-header-link-text {
      font-weight: 400;
      display: block;
      margin-top: 20px;

      .box-header-link {
        color: #0037C1; } }

    .box-header-loadbar {
      width: 100%;
      margin-top: 40px; } }

  .box-content {
    width: 100%;
    flex-grow: 2;
    @include flex-props-column-center;
    padding: 40px 0;
    @include phone {
      padding: 20px 0; } } }

.notch-block-main-guest {
  &:before {
    border-color: #190f39 #190f39 #0BFFB7 #0BFFB7; } }

.box-black-hr {
  height: 2px;
  width: 100%;
  margin-bottom: 40px;
  margin-top: 0;
  background-color: black;
  border: none;
  @include phone {
    margin-bottom: 20px; } }

.box-black-hr-top {
  margin-top: 40px;
  margin-bottom: 0;
  @include phone {
    margin-top: 20px; } }

.alternative-option-bar {
  width: 100%;
  @include flex-props-center;
  margin-bottom: 40px;
  @include phone {
    margin-bottom: 20px; }

  .alternative-option-bar-text {
    font-size: 16px;
    color: #000;
    flex-grow: 1;
    position: relative;
    @include phone {
      font-size: 14px; } }

  .alternative-option-bar-line {
    flex-grow: 5;
    background-color: black;
    border: none;
    height: 2px; } }

.info-header-guest {
  background: #0BFFB7;
  height: 20px;
  width: 100%;
  @include flex-props-center;

  .info-header-guest-icon {
    width: 13px;
    margin-right: 5px; }

  .info-header-guest-text {
    font-weight: 700;
    font-size: 11px; } }

.info-header {
  @include flex-props-column-center;
  margin: 40px 0 0;
  padding: 0 30px;
  position: relative;

  .info-header-subtitle {
    align-self: flex-start;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 20px; }

  .info-header-title {
    align-self: flex-start;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 25px; }

  .info-header-account {
    @include flex-props-space-between;
    width: 100%;
    margin-bottom: 20px;
    @include phone {
      flex-wrap: wrap; }

    .info-header-account-wrapper {
      @include flex-props-center;
      cursor: pointer;

      .info-header-account-title {
        font-weight: 700;
        font-size: 18px;
        white-space: nowrap;
        max-width: 150px;
        height: 22px;
        overflow-y: hidden;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        position: relative;
        @include phone {
          max-width: 80%;
          white-space: normal;
          height: auto;
          text-align: left;
          line-height: 22px;
          min-height: 22px;
          &:after {
            display: none; } }

        &::-webkit-scrollbar {
            display: none; }

        &::after {
          content: "";
          height: 18px;
          width: 3px;
          background: linear-gradient(0.25turn, transparent, #fff);
          position: absolute;
          right: 0;
          top: 0; }

        &-full {
          max-width: none;
          &:after {
            display: none; }
          @include phone {
            max-width: 80%;
            white-space: normal;
            height: auto;
            text-align: left;
            line-height: 22px;
            min-height: 22px; } } }



      .info-header-account-icon {
        width: 24px;
        height: 24px;
        margin-right: 4px;
        @include phone {
          margin-right: 10px; } }

      .info-header-account-arrow {
        width: 10px;
        padding-left: 4px;
        box-sizing: content-box; } }

    .info-header-account-backup {
      background: #0BFFB7;
      border: none;
      border-radius: 2px;
      @include flex-props-space-between;
      padding: 5px;
      cursor: pointer;
      @include phone {
        margin-top: 20px; }

      .info-header-account-backup-wrapper {
        @include flex-props-space-between; }

      .info-header-account-backup-text {
        font-weight: 700;
        font-size: 12px;
        color: #000; }

      .info-header-account-backup-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px; } } } }

