@import '../../global.sass';

.redirect {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  background-color: black;
  border: none;
  padding: 10px;
  cursor: pointer; }

.auth-page {
  background-image: url("../../assets/images/auth-background.jpg");
  width: 100vw;
  min-height: 100vh;
  @include flex-props-column-center;
  @include background-props;

  .auth-header-logo {
    margin: 30px 0 0 30px;
    align-self: start; }

  .auth-content {
    margin-top: 10px;
    position: relative;
    z-index: 2;
    flex-grow: 2;
    text-align: center; }

  .auth-logo-glitch {
    margin-top: 120px;
    padding-bottom: 20px;
    box-sizing: content-box;
    @include phone {
      margin-top: 50px; } }

  .auth-box-wrapper {
    @include phone {
      margin-top: 30px; } }

  .auth-page-beta-warning {
    position: absolute;
    right: 30px;
    top: 30px;

    .auth-page-beta-warning-button {
      font-weight: 700; } }

  .auth-page-beta-warning-info {
    width: 200px;
    padding: 10px 10px;
    background-color: #EBAB28;
    font-weight: 700; } }
