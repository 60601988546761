@import "../../../global";
$buttonnotchsize: 10px;
$seednotchsize: 10px;
$seedpolygon: polygon(0 0, #{$seednotchsize} 0, calc(100% - #{$seednotchsize}) 0%, 100% #{$seednotchsize}, 100% calc(100% - #{$seednotchsize}), calc(100%) 100%, #{$seednotchsize} 100%, 0 calc(100% - #{$seednotchsize}));

.seed-wrapper {
  width: 100%;

  .seed-form {
    @include flex-props-column-center;

    .seed-form-border-wrapper {
      width: 340px;
      min-height: 200px;
      background-color: #000;
      position: relative;
      clip-path: $seedpolygon;
      margin-bottom: 10px;
      @include phone {
        width: 260px; }

      .seed-form-wrapper {
        width: 338px;
        height: calc(100% - 2px);
        background-color: #fff;
        position: absolute;
        top: 1px;
        left: 1px;
        clip-path: $seedpolygon;
        @include phone {
          width: 258px; }

        .seed-form-header {
          margin-top: 12px;

          .seed-form-header-title {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 12px;
            text-transform: uppercase;
            text-align: left;
            margin-left: 20px; }

          .seed-form-hr {
            width: 100%;
            height: 1px; } }

        .seed-form-input-wrapper {
          padding: 20px;
          display: flex;
          flex-wrap: wrap;
          @include phone {
            padding: 15px; }

          .seed-form-word-wrapper {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            width: 33%;
            margin-bottom: 15px;
            @include flex-props-center;
            justify-content: flex-start;

            .seed-form-word-number {
              color: #0037C1;
              margin-right: 5px;
              font-weight: 700;
              font-size: 14px;
              @include phone {
                font-size: 12px; } }

            .seed-form-word {
              font-weight: 700;
              font-size: 15px;
              @include phone {
                font-size: 13px; } } }


          .seed-form-input-label {
            width: 33%;

            .seed-form-input {
              width: 100%;
              height: 25px;
              border: none;
              font-size: 14px;
              color: #000;
              text-align: center;
              @include phone {
                font-size: 13px; }

              &::placeholder {
                color: #0000004D;
                font-size: 14px;
                @include phone {
                  font-size: 13px; } }

              &:focus {
                outline: none; } } } } } }

    .seed-form-text {
      margin-bottom: 20px;
      text-align: left;
      max-width: 340px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      @include phone {
        max-width: 260px; } }

    .seed-form-clear {
      display: none;
      margin-bottom: 20px;
      text-align: right;
      justify-content: flex-end;
      width: 340px;
      @include phone {
        width: 260px; }

      .seed-form-clear-button {
        width: fit-content;
        background: none;
        padding: 0;
        border: none;
        border-bottom: 1px solid #000;
        @include flex-props-center;
        cursor: pointer;

        .seed-form-clear-text {
          color: #000;
          font-size: 14px;
          margin-right: 10px; }

        .seed-form-clear-cross {
          position: relative;
          width: 10px;
          height: 10px;

          .seed-form-clear-cross-line {
            width: 10px;
            height: 1px;
            background-color: #000;
            position: absolute;
            top: 4px;
            left: 0;
            transform: rotate(45deg);

            &:last-child {
              transform: rotate(135deg); } } } } }

    .seed-form-clear-active {
      display: flex; } } }
