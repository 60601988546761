@import "../../global";

.idb-tutorial-wrapper {
  padding: 0 20px;
  @include flex-props-column-center;

  .idb-description {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px; }

  .idb-solution {

    .idb-solution-item {

      &:last-child {
        .idb-solution-item-image {
          margin-bottom: 0; }
        .idb-solution-item-text:last-child {
          margin-bottom: 0; } }

      .idb-solution-item-text {
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        margin-bottom: 15px;
        display: block; }

      .idb-solution-item-text-underline {
        padding-bottom: 1px;
        border-bottom: 1px solid #000;
        cursor: pointer;
        width: fit-content;
        margin: 0 auto; }

      .idb-solution-item-image {
        width: 90%;
        margin-bottom: 15px; }

      .idb-solution-item-link {
        margin: 0 auto 15px;
        color: #000;
        width: fit-content;
        border-bottom: 1px solid #0037C1;
        padding-bottom: 5px; } } } }
