.empower-wrapper {
  width: 90%;
  text-align: center;
  padding: 20px 0;

  .empower-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px; }

  .empower-qr-code {
    margin-bottom: 20px; }

  .empower-scopes {
    font-size: 14px;
    text-align: left;
    margin: 0 0 20px;

    .empower-scopes-title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px; }

    .empower-scopes-item {
      margin-bottom: 5px;
      font-weight: 500;
      overflow-wrap: break-word; }

    & span {
      display: block; } } }
