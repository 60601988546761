@import "../../../../global";
$accountnotchsize: 10px;
$accountpolygon: polygon(0 0, #{$accountnotchsize} 0, calc(100% - #{$accountnotchsize}) 0%, 100% #{$accountnotchsize}, 100% calc(100% - #{$accountnotchsize}), calc(100%) 100%, #{$accountnotchsize} 100%, 0 calc(100% - #{$accountnotchsize}));


.account-dropdown-wrapper {
  @include flex-props-column-center;
  width: 333px;
  margin-bottom: 20px;
  @include phone {
    width: 270px; }

  &:last-child {
    margin-bottom: 0; }

  .account-dropdown-border-container {
    width: 100%;
    height: 40px;
    background-color: black;
    box-sizing: border-box;
    position: relative;
    clip-path: $accountpolygon;

    .account-dropdown {
      position: absolute;
      background-color: white;
      top: 1px;
      left: 1px;
      width: 331px;
      height: 38px;
      display: flex;
      align-items: center;
      cursor: pointer;
      clip-path: $accountpolygon;
      @include phone {
        width: 268px; }

      & div:first-child {
        background-color: #000;
        align-self: baseline; }

      .account-dropdown-address-wrapper {
        padding: 7px 0;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 2;

        .account-dropdown-address-type {
          font-weight: 700;
          font-size: 8px;
          color: #0037C1;
          margin-left: 10px;
          text-transform: uppercase; } }


      .account-dropdown-address {
        font-size: 14px;
        font-weight: 700;
        flex-grow: 2;
        text-align: left;
        margin-left: 10px;
        @include phone {
          font-size: 13px; } }

      .account-dropdown-connections {
        width: 40px;
        height: 100%;
        border-left: 1px solid #000;
        @include flex-props-center;
        @include phone {
          width: 0;
          border-left: none; }

        .account-dropdown-connections-number {
          display: none; }

        .account-dropdown-connections-arrow {
          display: block; } }

      .account-dropdown-connections-active {
        background-color: #000;
        border-left: none;

        .account-dropdown-connections-arrow {
          display: none; }

        .account-dropdown-connections-number {
          display: block;
          font-weight: 700;
          font-size: 14px;
          color: white;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            top: -8px;
            right: -7px;
            background-color: #0BFFB7; } } } }

    .account-dropdown-active {
      clip-path: polygon(0 0, #{$accountnotchsize} 0, calc(100% - #{$accountnotchsize}) 0%, 100% #{$accountnotchsize}, 100% calc(100% - #{$accountnotchsize}), calc(100%) 100%, #{$accountnotchsize} 100%, 0 100%); } }

  .account-dropdown-border-container-active {
    clip-path: polygon(0 0, #{$accountnotchsize} 0, calc(100% - #{$accountnotchsize}) 0%, 100% #{$accountnotchsize}, 100% calc(100% - #{$accountnotchsize}), calc(100%) 100%, #{$accountnotchsize} 100%, 0 100%); }

  .account-dropdown-content-wrapper {
    width: 333px;
    height: 185px;
    position: relative;
    background-color: black;
    clip-path: polygon(0 0, 0 0, 100% 0%, 100% 0, 100% calc(100% - #{$accountnotchsize}), calc(100%) 100%, #{$accountnotchsize} 100%, 0 calc(100% - #{$accountnotchsize}));
    @include phone {
      width: 250px;
      height: 200px; }

    .account-dropdown-content {
      position: absolute;
      top: 0;
      left: 1px;
      background-color: white;
      border-top: none;
      padding: 20px;
      width: 331px;
      height: 184px;
      box-sizing: border-box;
      clip-path: polygon(0 0, 0 0, 100% 0%, 100% 0, 100% calc(100% - #{$accountnotchsize}), calc(100%) 100%, #{$accountnotchsize} 100%, 0 calc(100% - #{$accountnotchsize}));
      @include phone {
        width: 248px;
        height: 199px; }

      .account-dropdown-content-list {
        text-align: left;

        .account-dropdown-content-list-item {
          @include flex-props-column-center;
          align-items: flex-start;
          margin-bottom: 10px;

          .account-dropdown-content-list-item-name {
            font-size: 12px;
            margin-bottom: 5px;
            color: #858593;
            font-weight: 700; }

          .account-dropdown-content-list-item-value {
            font-size: 14px;
            font-weight: 700; }

          .account-dropdown-content-list-item-address {
            cursor: pointer;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none; } } }

      .account-dropdown-content-terminate {
        width: 100%;
        height: 40px;
        background-color: #FF6C6C;
        border: none;
        color: white;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        position: absolute;
        bottom: 0;
        left: 0;
        cursor: pointer; } } } }


.account-dropdown-connections-number-tooltip {
  font-family: "Nexa", sans-serif;
  border-radius: 0 !important; }
