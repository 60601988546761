@import "../../global";

.document-page {
  background-image: url("../../assets/images/auth-background.jpg");
  width: 100vw;
  height: 100vh;
  @include flex-props-column-center;
  @include background-props;

  b, strong {
    font-weight: 800; }

  .document-header-logo {
    margin: 30px 0 0 30px;
    align-self: start; }

  .document-content-wrapper {
    margin-top: 10px;
    width: 80vw;
    height: 80vh;
    flex-grow: 2;
    z-index: 2;
    position: relative;
    @include flex-props-column-center;
    @include small-phone {
      height: 70vh;
      width:  90vw;
      margin-top: 30px; }

    .document-go-back {
      @include flex-props-center;
      align-self: flex-start;
      margin-bottom: 20px;

      .document-go-back-arrow {
        width: 25px; }

      .document-go-back-text {
        font-size: 18px;
        color: #fff;
        font-weight: 700; } }

    .document-content {
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      overflow-y: scroll;
      padding: 30px;
      @include small-phone {
        padding: 20px; }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        background-color: #fff;
        border-radius: 10px; }

      &::-webkit-scrollbar {
        width: 10px;
        background-color: #F5F5F5; }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-image: -webkit-gradient(linear,left bottom, left top, color-stop(0.44, rgb(122,153,217)), color-stop(0.72, rgb(73,125,189)), color-stop(0.86, rgb(28,58,148))); }


      &:before, &:after {
        content: "";
        width: 50px;
        height: 30px;
        transform: rotate(45deg);
        position: absolute;
        background-color: #0b0c28; }

      &:before {
        top: -10px;
        right: -18px; }

      &:after {
        bottom: -8px;
        left: -18px; }

      .document-content-md {
        &:after {
          content: "";
          display: block;
          height: 30px;
          width: calc(99% - 10px);
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: #fff; }

        & h1 {
          font-weight: 800;
          font-size: 28px;
          margin-bottom: 20px;
          text-align: center; }

        & h2 {
          font-weight: 800;
          font-size: 24px;
          margin: 20px 0;
          text-align: left; }

        & h3 {
          font-weight: 800;
          font-size: 18px;
          margin: 15px 0 15px 15px;
          text-align: left; }

        & h4 {
          font-weight: 800;
          font-size: 16px;
          margin: 15px 0 15px 30px;
          text-align: left; }

        & ol {
          margin-left: 30px;
          padding-left: 15px;
          margin-bottom: 15px;
          list-style: decimal;

          & li {
            margin-bottom: 10px;
            &:last-child {
              margin-bottom: 0; } } }

        & table {
          table-layout: fixed;
          width: 80%;
          margin: 20px auto;

          @include phone {
            width: 100%; }

          & th {
            font-size: 16px;
            font-weight: 800; }

          & td, th {
            padding: 10px;
            border: 1px solid #000;
            @include small-phone {
              font-size: 12px; } } }


        & p {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 10px;

          & a {
            text-decoration: underline #0037c1;
            color: #0037c1; } }

        & ul {
          & li {
            margin-bottom: 5px;

            & ul {
              padding-left: 20px; }

            &:last-child {
              margin-bottom: 0; }

            & a {
              margin-bottom: 10px;
              display: inline-block;
              color: #0037c1;
              text-decoration: underline #0037c1; } } } } } }

  .document-logo-glitch {
    margin-top: 120px;
    padding-bottom: 20px;
    box-sizing: content-box;
    @include phone {
      margin-top: 50px; } } }



